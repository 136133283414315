import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import { isLoggedIn } from '../utils/auth.js' // 导入刚才创建的检查登录状态的函数

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: { requiresAuth: true } // 添加元数据字段，指示这个路由需要认证
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        }
    ]
})

// 添加导航守卫
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 这个路由需要认证，请检查是否已经登录
        // 如果没有登录，则重定向到登录页面
        if (!isLoggedIn()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath } // 将要去的路由作为参数，登录后可以重定向过去
            })
        } else {
            next() // 已登录，继续
        }
    } else {
        next() // 确保一定要调用 next()
    }
})

export default router;
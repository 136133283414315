<template>
  <div v-if="visible" :class="`message ${type}`" @click="visible = false">
    {{ message }}
  </div>
</template>

<script>
export default {
  props: {
    message: String,
    type: {
      type: String,
      default: 'info' // 默认为info类型，你可以设置为error, warning, success等
    }
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    message: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.visible = true;
          setTimeout(() => {
            this.visible = false;
          }, 3000); // 3秒后自动隐藏消息提示
        }
      }
    }
  }
}
</script>

<style>
.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 10px;
  opacity: 0.8;
}
.info { background-color: #2196F3; }
.success { background-color: #4CAF50; }
.warning { background-color: #FFC107; }
.error { background-color: #F44336; }
</style>

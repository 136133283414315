<template>
  <div class="app">
    <div class="left">
      <div class="left_header">
        <div class="addbtn" @click="showDialog">新建辩论</div>
      </div>
      <div class="left_list">
        <li v-for="(item, index) in items" :key="index" @click="toggleSelected(index,item)" :class="{ active: selectedItem === index }">{{ item.debateTopic }}</li>
      </div>

    </div>
    <div class="right" >
      <div class="contentSdk">
        <div class="li_box">
          <!--        <li v-for="(item, index) in items" :key="index"><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">{{item.arguerPrologue}}</div></li>-->
          <!--        <li><img src="../assets/left.png" class="imgleft"><div class="txtbox spanleft">131313113</div></li>-->
          <!--        <li><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">131313113</div></li>-->
          <template v-for="(item,index) in detail" >
            <li v-if="item.debate"><img src="../assets/left.png" class="imgleft"><div class="txtbox spanleft">
              {{ item.debate }}
            </div>
            </li>
            <li v-if="item.content"><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">
              {{ item.content }}
              <p class="pay_box" >
            <span class="pay_box_border" >
               <span class="voices" :class="{'play':item.is_pay}">
              <span class="loading">
                <hr class="hr1" />
                <hr class="hr2" />
                <hr class="hr3" />
                <hr class="hr4" />
                <hr class="hr5" />
              </span>
            </span>
               <svg v-show="!item.is_pay" @click="startPay(item,index)" t="1711692455159" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1594" id="mx_n_1711692455160" width="200" height="200"><path d="M772.7 217.7a32.2 32.1 0 1 0 64.4 0 32.2 32.1 0 1 0-64.4 0Z" fill="#0bc8d9" p-id="1595"></path><path d="M415.8 679.9c5.9 0 11.5-1.6 16.2-4.5l231.1-134.6c10.9-5.2 18.5-16.3 18.5-29.2 0-11.9-6.4-22.3-16-27.8L439.7 352.2c-5.8-6.7-14.4-10.9-23.9-10.9-17.6 0-31.8 14.4-31.8 32.1 0 0.6 0 1.2 0.1 1.8l-0.4 0.2 0.5 269c-0.1 1.1-0.2 2.2-0.2 3.4 0 17.7 14.3 32.1 31.8 32.1z" fill="#0bc8d9" p-id="1596"></path><path d="M909.8 306.6c-5.4-10.5-16.3-17.8-28.9-17.8-17.8 0-32.2 14.4-32.2 32.1 0 6 1.7 11.7 4.6 16.5l-0.1 0.1c26.9 52.4 42.1 111.8 42.1 174.7 0 211.6-171.6 383.2-383.2 383.2S128.8 723.8 128.8 512.2 300.4 129.1 512 129.1c62.5 0 121.5 15 173.6 41.5l0.2-0.4c4.6 2.6 10 4.1 15.7 4.1 17.8 0 32.2-14.4 32.2-32.1 0-13.1-7.9-24.4-19.3-29.4C653.6 81.9 584.9 64.5 512 64.5 264.7 64.5 64.3 265 64.3 512.2S264.7 959.9 512 959.9s447.7-200.4 447.7-447.7c0-74.1-18-144-49.9-205.6z" fill="#0bc8d9" p-id="1597"></path></svg>
              <svg v-show="item.is_pay" @click="startPay(item,index)" t="1711692597706" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1883" id="mx_n_1711692597707" width="200" height="200"><path d="M121.3 680.4m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z" fill="#0bc8d9" p-id="1884"></path><path d="M266.5 855.6m-40.9 0a40.9 40.9 0 1 0 81.8 0 40.9 40.9 0 1 0-81.8 0Z" fill="#0bc8d9" p-id="1885"></path><path d="M512 35.6C252.8 35.6 42.6 245.7 42.6 505c0 69 14.9 134.6 41.7 193.6l74.2-35.4c-21.7-48.3-33.7-101.8-33.7-158.2 0-213.8 173.3-387.2 387.2-387.2S899.2 291.1 899.2 505 725.8 892.1 512 892.1c-81.9 0-157.9-25.4-220.4-68.8l-51.2 57.2c1.5 0.7 2.5 2.1 2.5 3.6s0.3 3.2-1.2 3.9c76.7 54.5 169.2 86.2 270.4 86.2 259.2 0 469.4-210.1 469.4-469.4S771.2 35.6 512 35.6z" fill="#0bc8d9" p-id="1886"></path><path d="M419.9 329.1c22.6 0 41 18.3 41 41v261.7c0 22.6-18.3 41-41 41-22.6 0-41-18.3-41-41V370.1c0-22.6 18.4-41 41-41zM610.9 329.1c22.6 0 41 18.3 41 41v261.7c0 22.6-18.3 41-41 41-22.6 0-41-18.3-41-41V370.1c0-22.6 18.4-41 41-41z" fill="#0bc8d9" p-id="1887"></path></svg>

            </span>

              </p>
            </div>
            </li>
          </template>

        </div>



        <div class="loading_box" v-if="isLoading">
          <div  class="loader"></div>
        </div>




      </div>
      <!--      <div id="chat-window">
            </div>-->
      <div id="operator" v-if="is_show">
        <div class="input_box">
          <div class="textarea">{{textarea}}</div>
          <span class="tooltip" @mouseover="showTooltip2 = true" @mouseleave="showTooltip2 = false">
             <p class="tooltiptext2" v-show="showTooltip2">发送</p>
             <svg t="1711959964029" class="icon" @click="SendMsg" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="25203" width="200" height="200"><path d="M512.877641 511.997001m-301.216175 301.216175a425.984 425.984 0 1 0 602.43235-602.43235 425.984 425.984 0 1 0-602.43235 602.43235Z" fill="#0bc8d9" p-id="25204" data-spm-anchor-id="a313x.search_index.0.i17.f9d53a81goWnbG" class="selected"></path><path d="M266.24 427.7248l422.5024-103.5776a24.9856 24.9856 0 0 1 28.4672 34.9696l-188.2624 395.1616a24.9344 24.9344 0 0 1-46.08-3.072l-47.9744-149.4016a24.7808 24.7808 0 0 1 5.12-24.3712L522.24 486.4a6.6048 6.6048 0 0 0-8.192-10.24l-109.0048 63.6416a24.9856 24.9856 0 0 1-24.6272 0.3072L260.2496 473.8048a24.9856 24.9856 0 0 1 5.9904-46.08z" fill="#FFFFFF" p-id="25205"></path></svg>
          </span>

        </div>
        <div class="summary" @click="debateSummary">

          <span class="tooltip" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
             <p class="tooltiptext" v-show="showTooltip">总结</p>
            <svg t="1711693381156" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="28370" width="200" height="200"><path d="M512 512m-510.976 0a510.976 510.976 0 1 0 1021.952 0 510.976 510.976 0 1 0-1021.952 0Z" fill="#0bc8d9" p-id="28371" data-spm-anchor-id="a313x.search_index.0.i12.55b53a815vhbNc" class="selected"></path><path d="M713.216 217.088h-402.432c-33.28 0-55.296 22.016-55.296 49.664V757.76c0 27.648 27.648 49.664 55.296 49.664h402.432c33.28 0 55.296-22.016 55.296-49.664v-491.008c0-27.648-22.016-49.664-55.296-49.664z m-374.784 110.08h342.016c11.264 0 16.384 5.632 16.384 16.384 0 11.264-5.632 16.384-16.384 16.384h-342.016c-5.632 0-16.384-5.632-16.384-16.384s10.752-16.384 16.384-16.384z m0 110.592h342.016c11.264 0 16.384 5.632 16.384 16.384 0 11.264-5.632 16.384-16.384 16.384h-342.016c-5.632 0-16.384-5.632-16.384-16.384s10.752-16.384 16.384-16.384z m-16.896 126.464c0-11.264 5.632-16.384 16.384-16.384h342.016c11.264 0 16.384 5.632 16.384 16.384s-5.632 16.384-16.384 16.384H337.92c-5.12 0.512-16.384-5.12-16.384-16.384z m408.064 82.944l-121.344 121.344c-5.632 5.632-5.632 5.632-11.264 5.632s-5.632 0-11.264-5.632l-55.296-60.416c-5.632-5.632-5.632-16.384 0-22.016s16.384-5.632 22.016 0l44.032 49.664 110.08-110.08c5.632-5.632 16.384-5.632 22.016 0 6.656 5.12 6.656 15.872 1.024 21.504z" fill="#FFFFFF" p-id="28372"></path></svg>
          </span>
        </div>
        <div class="voice" v-if="voice_loading">
          <div class="loading">
            <hr class="hr1" />
            <hr class="hr2" />
            <hr class="hr3" />
            <hr class="hr4" />
            <hr class="hr5" />
            <hr class="hr6" />
            <hr class="hr7" />
            <hr class="hr8" />
            <hr class="hr9" />
            <hr class="hr10" />
          </div>
        </div>
        <div class="footerSdk">

          <span  class="metavoice tooltip" @click="voice = !voice" @mouseover="showTooltip1 = true" @mouseleave="showTooltip1 = false">
           <p class="tooltiptext1" v-show="showTooltip1 && !recording">录音</p>
           <p class="tooltiptext1" v-show="showTooltip1 && recording">关闭</p>
        <svg v-show="!recording" @click="startRecording" t="1682388219936" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" p-id="1542" width="20" height="20">
                  <path
                      d="M211.2 32l567.466667 981.333333c4.266667 6.4 10.666667 10.666667 19.2 10.666667 17.066667 0 25.6-17.066667 19.2-32L249.6 10.666667c-6.4-6.4-12.8-10.666667-21.333333-10.666667-17.066667 0-25.6 17.066667-17.066667 32zM746.666667 512V234.666667c0-130.133333-104.533333-234.666667-234.666667-234.666667-70.4 0-132.266667 29.866667-174.933333 78.933333l343.466666 595.2C721.066667 633.6 746.666667 576 746.666667 512zM512 746.666667c19.2 0 38.4-2.133333 57.6-6.4L277.333333 234.666667v277.333333c0 130.133333 104.533333 234.666667 234.666667 234.666667zM810.666667 405.333333c-12.8 0-21.333333 8.533333-21.333334 21.333334v85.333333c0 78.933333-34.133333 151.466667-87.466666 200.533333l21.333333 36.266667c66.133333-61.866667 108.8-149.333333 108.8-247.466667V426.666667c0-12.8-8.533333-21.333333-21.333333-21.333334z"
                      fill="#ffffff"
                      p-id="1543"
                  ></path>
                  <path
                      d="M682.666667 981.333333h-149.333334v-149.333333c27.733333-2.133333 53.333333-8.533333 78.933334-17.066667l-21.333334-36.266666c-29.866667 8.533333-59.733333 12.8-93.866666 10.666666-149.333333-6.4-262.4-136.533333-262.4-285.866666V426.666667c0-12.8-8.533333-21.333333-21.333334-21.333334s-21.333333 8.533333-21.333333 21.333334v85.333333c0 168.533333 132.266667 307.2 298.666667 320v149.333333h-149.333334c-12.8 0-21.333333 8.533333-21.333333 21.333334s8.533333 21.333333 21.333333 21.333333h341.333334c12.8 0 21.333333-8.533333 21.333333-21.333333s-8.533333-21.333333-21.333333-21.333334z"
                      fill="#ffffff"
                      p-id="1544"
                  ></path>
                </svg>
        <svg v-show="recording" @click="stopRecording" t="1682388877699" class="icon" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" p-id="2009" width="20" height="20">
          <path
              d="M810.666667 405.333333c-12.8 0-21.333333 8.533333-21.333334 21.333334v85.333333c0 157.866667-132.266667 285.866667-292.266666 277.333333-149.333333-6.4-262.4-136.533333-262.4-285.866666V426.666667c0-12.8-8.533333-21.333333-21.333334-21.333334s-21.333333 8.533333-21.333333 21.333334v85.333333c0 168.533333 132.266667 307.2 298.666667 320v149.333333h-149.333334c-12.8 0-21.333333 8.533333-21.333333 21.333334s8.533333 21.333333 21.333333 21.333333h341.333334c12.8 0 21.333333-8.533333 21.333333-21.333333s-8.533333-21.333333-21.333333-21.333334h-149.333334v-149.333333c168.533333-10.666667 298.666667-157.866667 298.666667-328.533333V426.666667c0-12.8-8.533333-21.333333-21.333333-21.333334zM512 746.666667c130.133333 0 234.666667-104.533333 234.666667-234.666667V234.666667c0-130.133333-104.533333-234.666667-234.666667-234.666667S277.333333 104.533333 277.333333 234.666667v277.333333c0 130.133333 104.533333 234.666667 234.666667 234.666667z"
              fill="#ffffff"
              p-id="2010"
          ></path>
        </svg>
            </span>
        </div>
      </div>
    </div>

    <!-- Overlay -->
    <div class="overlay" v-show="show">
      <!-- Dialog -->
      <div class="dialog">
        <form>
          <div class="input-container">
            <label for="name">姓名:</label>
            <input type="text" v-model="formData.debateName">
          </div>


          <div class="input-container">
            <label for="email">辩题:</label>
            <textarea v-model="formData.debateTopic"></textarea>
          </div>
          <div class="input-container">
            <label for="email">立场:</label>
            <textarea v-model="formData.debateStandpoint"></textarea>
          </div>

          <div class="button_box">
            <button  @click="closeDialog" class="closebut">取消</button>
            <button @click="submitForm" type="button">开始</button>
          </div>

        </form>
      </div>
    </div>

    <!-- Loading Overlay -->
    <div class="overlays" v-show="loading">
      <div class="spinner"></div>
    </div>






  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import { hex_md5 } from "@/utils/md5.js";
import {Howl} from "howler";

export default {
  name: "Home",
  mounted() {
    //初始化
    this.init();
    this.getItem();
    this.chatWindow = document.querySelector(".contentSdk");
  },
  beforeDestroy() {
    this.uninit();
  },
  data() {
    return {
      //ChatGPT
      openApiKey: "", //ChatGPT APIKey
      configuration: null,
      openai: null,
      host: "https://arguer.newmin.cn",
      //host: "http://127.0.0.1:8032",
      modelEngine: "text-davinci-003",
      chatCount: 0,
      debate_id: 0,
      items: [],
      detail: [],
      selectedItem: null,
      show: false,
      is_pay: false,
      voice_loading: false,
      showTooltip: false,
      showTooltip1: false,
      showTooltip2: false,
      is_show: false,
      loading: false,
      isLoading: false, // 控制动画显示的变量
      textarea: '',
      formData: {
        debateName: '',
        debateTopic: '',
        debateStandpoint: ''
      },

      //科大讯飞
      appid: "79baf441", //科大讯飞 AppID
      apiKey: "600a8c272e638b356a710a66aedd4046", //科大讯飞 APIKey
      uri: "wss://rtasr.xfyun.cn/v1/ws", // 科大讯飞地址
      result: "",
      recording: false,
      sessionID: "",

      socket: null,

      //audio stream
      audioStream: null,
      streamSource: null,
      audioContext: null,
      audioInput: null,
      arguerPrologue: null,
      audioFile: null,
      audioSampleRate: 16000,
      audioBuffer: [],
      audioBufferSize: 0,
      audioSampleBits: 16,
      audioChannels: 1,

      recWorker: null,
      processer: null,
      soundBgm: null,

      handlerInterval: null,
      chatWindow:null,

      state: "none",
    };
  },
  methods: {
    toggleLoading() {
      console.log('toggleLoading')
      console.log(this.loading)
      this.loading = !this.loading;
    },
    startPay(item,index) {
      console.log(this.detail[index])
      this.detail[index].is_pay = !item.is_pay;
      console.log("item.is_pay")
      console.log(this.detail[index])
      if(item.is_pay) {
        console.log("item.audioFile")
        console.log(item.audioFile)
        this.start_pay(item.audioFile)
      }else {
        if (this.soundBgm != null) {
          this.soundBgm.pause();
        }
      }


      // this.is_pay = !this.is_pay;
      // if(this.is_pay) {
      //   this.start_pay(this.audioFile)
      // }else {
      //   if (this.soundBgm != null) {
      //     this.soundBgm.pause();
      //   }
      // }
    },
    toggleSelected(index,item) {
      this.selectedItem = index === this.selectedItem ? null : index;
      this.is_show = true;
      this.debate_id = item.id;
      // this.arguerPrologue = item.arguerPrologue;
      // this.audioFile = item.audioFile;
      //document.querySelector(".contentSdk").innerHTML = '';
      //let chatWindow = document.querySelector(".contentSdk");
      // chatWindow.innerHTML = '<li><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">'+item.arguerPrologue+'</div></li>';
      this.detail = [{debate: '', content: item.arguerPrologue, audioFile: item.audioFile,is_pay: false}]
      this.chatWindow.scrollTop = this.chatWindow.scrollHeight;




      this.getDebateDetailList(item.id);
      console.log(item)
    },
    showDialog() {
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
    getDebateDetailList(id) {
      let that = this;
      const url = this.host+"/api/getDebateDetailList?id="+id;
      fetch(url, {
        method: "GET",
        headers: new Headers({
          "token": localStorage.getItem("token"),
        }),
      }) .then(
          (response) => response.json(),
          (error) => {
            console.error(error);
          }
      ).then((data) => {
        console.log("getDebateDetailList");
        console.log(data);
        if(data.code === 200) {
          //let chatWindow = document.getElementsByClassName('contentSdk')[0]
          //let chatWindow = document.querySelector(".contentSdk");

          that.detail = that.detail.concat(data.data)
          //let res = data.data
          // for (let i = 0; i < res.length; i++) {
          //   if(res[i].debate != '') {
          //     chatWindow.innerHTML += ' <li><img src="../assets/left.png" class="imgleft"><div class="txtbox spanleft">'+res[i].debate+'</div></li>';
          //   }
          //   chatWindow.innerHTML += ' <li><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">'+res[i].content+'</div></li>';
          // }
          //延时
          setTimeout(() => {
            this.chatWindow.scrollTop = this.chatWindow.scrollHeight;
          }, 200);


        }else {
          console.log(data.data)
        }

      });
    },
    getItem() {
      const url = this.host+"/api/getDebateList";
      let that = this;
      fetch(url, {
        method: "GET",
        headers: new Headers({
          "token": localStorage.getItem("token"),
        }),
      }) .then(
          (response) => response.json(),
          (error) => {
            console.error(error);
          }
      ).then((data) => {
        console.log("getDebateList");
        console.log(data);
        if(data.code === 200) {
          that.items = data.data;

        }else {
          console.log(data.data)
        }

      });




    },
    debateSummary() {


      const url = this.host+"/api/debateSummary";
      let that = this;

      console.log(url);


      fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "token": localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          id: that.debate_id,
        }),
      }) .then(
          (response) => response.json(),
          (error) => {
            console.error(error);
          }
      ).then((data) => {
        console.log(data);
        if(data.code === 200) {
          that.detail = that.detail.concat(data.data)
          // let chatWindow = document.querySelector(".contentSdk");
          // chatWindow.innerHTML += ' <li><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">'+data.data.content+'</div></li>';
          setTimeout(() => {
            this.chatWindow.scrollTop = this.chatWindow.scrollHeight;
          }, 200);
          //that.start_pay(data.data.audioFile)
        }else {
          console.log(data.data)
        }

      });




    },
    submitForm() {
      // Handle form submission
      console.log('Form submitted with data:', this.formData);
      this.closeDialog();
      this.toggleLoading();

      const url = this.host+"/api/createDebate";
      let that = this;

      console.log(url);


      fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "token": localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          debateName: that.formData.debateName,
          debateTopic: that.formData.debateTopic,
          debateStandpoint: that.formData.debateStandpoint
        }),
      }) .then(
          (response) => response.json(),
          (error) => {
            console.error(error);
          }
      ).then((data) => {
        console.log(data);
        if(data.code === 200) {
          that.toggleLoading();
          that.getItem();
          that.is_show = true;
          that.debate_id = data.data.id;
          //let chatWindow = document.getElementsByClassName('contentSdk')[0]
          //let chatWindow = document.querySelector(".contentSdk");
          //chatWindow.innerHTML += ' <li><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">'+data.data.arguerPrologue+'</div></li>';
          // that.arguerPrologue = data.data.arguerPrologue;
          // that.audioFile = data.data.audioFile;
          that.detail = [{debate: '', content: data.data.arguerPrologue, audioFile: data.data.audioFile,is_pay: false}]
          setTimeout(() => {
            this.chatWindow.scrollTop = this.chatWindow.scrollHeight;
          }, 200);
          // that.is_pay = true;
          // that.start_pay(data.data.audioFile)
        }else {
          console.log(data.data)
        }

      });




    },
    SendMsg(){
      if (this.textarea === '') {
        return
      }
      this.stopRecording();
      this.isLoading = true;
      this.getOpenAiReplay2(this.textarea);
    },
    resetDots(){
      this.dots=[]
    },
    startRecording() {
      this.recording = true;
      //开始采集音频数据
      console.log("开始采集音频数据");
      if (this.soundBgm != null) {
        this.soundBgm.pause();
      }
      this.captureAudio();
    },
    stopRecording() {
      this.recording = false;
      this.voice_loading = false;

      //关闭音频
      this.closeAudio();

      // this.removeLastEle()
    },
    closeSocket(){
      //关闭websocket
      clearInterval(this.handlerInterval);
      this.socket.close();
      this.socket = null;
    },
    init() {
      console.log("initialize ......");
      this.state = "init";
      this.createWorker();
    },
    uninit() {
      console.log("uninit......");
      this.state = "terminal";

      this.closeAudio();
      this.recWorker.terminate();
    },
    createWorker() {
      this.recWorker = new Worker(
          new URL("../workers/worker", import.meta.url),
          { type: "module" }
      );

      this.recWorker.onmessage = (event) => {
        // this.result += event.data;
        this.audioBuffer.push(...event.data.buffer);
      };

      this.recWorker.onerror = function (event) {
        console.log("=======>", event.message, event.filename, event.lineno);
        this.recorder.terminate();
      };
    },
    captureAudio() {
      if (
          this.state === "init" ||
          this.state === "end" ||
          this.state === "pause"
      ) {
        this.state = "ing";

        this.audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
        if (this.audioContext) {
          this.processer = this.audioContext.createScriptProcessor(0, 1, 1);
          console.log("创建处理程序......");
        }

        console.log("开始捕获音频数据 ...");



        //采集音频数据
        if (navigator.mediaDevices && (navigator.mediaDevices.getUserMedia)) {
          navigator.mediaDevices
              .getUserMedia({ audio: true, video: false })
              .then(
                  (stream) => {
                    this.audioStream = stream;

                    this.streamSource =
                        this.audioContext.createMediaStreamSource(stream);

                    this.processer.onaudioprocess = (e) => {
                      this.sendData(e.inputBuffer.getChannelData(0));
                    };

                    this.streamSource.connect(this.processer);

                    this.processer.connect(this.audioContext.destination);
                  },
                  (e) => {
                    console.log("没有音频采集设备，请插入耳机！");
                    console.log("error:", 'e');
                  }
              );
        } else {
          navigator.getUserMedia({ audio: true, video: false }).then(
              (stream) => {
                this.mediaStream =
                    this.audioContext.createMediaStreamSource(stream);
                this.recorder.onaudioprocess = (e) => {
                  console.log("节点设备，记录器.onaujdioprocess......");
                };
              },
              (e) => {
                console.log("error:", e);
              }
          );
        }

        //打开websocket
        this.createWebSocket();

      } else {
        console.log("你没有初始化......");
      }
    },
    closeAudio() {
      console.log("关闭音频。。。:", this.state);
      if (this.state === "ing") {
        this.state = "pause";

        if (this.streamSource) this.streamSource.disconnect();
        if (this.processer) this.processer.disconnect();

        if (this.audioStream) {
          this.audioStream.getTracks().forEach((track) => {
            track.stop();
          });
          this.audioStream = null;
        }

        this.processer.onaudioprocess = null;
        this.processer = null;

        this.audioContext.close();
        this.audioContext = null;

        this.closeSocket()
      }
    },
    createWebSocket() {
      console.log("创建websocket ...");

      let urlParam = this.handShakeParams();

      let url = this.uri + urlParam;
      //console.log(url);
      if ("WebSocket" in window) {
        this.socket = new WebSocket(url);
      } else if ("MozWebSocket" in window) {
        this.socket = new MozWebSocket(url);
      } else {
        alert(notSupportTip);
        return null;
      }

      this.socket.onopen = (e) => {
        this.processWsOpen();
      };
      this.socket.onmessage = (e) => {
        this.processWsMessage(e);
      };
      this.socket.onerror = (e) => {
        console.log("关闭连接ws.onerror");
      };
      this.socket.onclose = (e) => {
        console.log("关闭连接ws.onclose");
      };
    },
    handShakeParams() {
      let appId = this.appid;
      let secretKey = this.apiKey;
      let ts = Math.floor(new Date().getTime() / 1000);
      console.log(ts)
      let signa = hex_md5(appId + ts);
      let signatureSha = CryptoJS.HmacSHA1(signa, secretKey);
      let signature = CryptoJS.enc.Base64.stringify(signatureSha);
      signature = encodeURIComponent(signature);
      return "?appid=" + appId + "&ts=" + ts + "&signa=" + signature;
    },
    sendData(buf) {
      this.recWorker.postMessage({
        command: "transform",
        buffer: buf,
      });
    },
    processWsOpen() {
      if (!this.socket) {
        console.warn("socket is null");
        return;
      }

      if (this.socket.readyState !== 1) {
        return;
      }

      var audioData = this.audioBuffer.splice(0, 1280);

      //this.addChatMessage("",'sent')

      this.socket.send(new Int8Array(audioData));

      this.handlerInterval = setInterval(() => {
        // websocket未连接
        if (this.socket.readyState !== 1) {
          clearInterval(this.handlerInterval);
          return;
        }

        if (this.audioBuffer.length === 0) {
          if (this.state === "end") {
            this.ws.send('{"end": true}');
            console.log("发送结束标识");
            clearInterval(this.handlerInterval);
          }
          return false;
        }

        var audioData = this.audioBuffer.splice(0, 1280);
        if (audioData.length > 0) {
          this.socket.send(new Int8Array(audioData));
        }
      }, 40);
    },
    processWsMessage(e) {
      let jsonData = JSON.parse(e.data);
      if (jsonData.action == "started") {
        console.log("握手成功");
        this.voice_loading = true;
      } else if (jsonData.action == "result") {
        this.setResult(JSON.parse(jsonData.data));
      } else if (jsonData.action == "error") {
        console.log("出错了:", jsonData);
      }
    },
    setResult(data) {
      let rtasrResult = [];
      rtasrResult[data.seg_id] = data;
      rtasrResult.forEach((i) => {
        if (i.cn.st.type == 0) {
          let str = "";
          //获得结果：
          i.cn.st.rt.forEach((j) => {
            j.ws.forEach((k) => {
              k.cw.forEach((l) => {
                str += l.w.trim();
              });
            });
          });

          console.log("录音结果："+str)
          this.textarea = this.textarea+str
          //this.stopRecording();
          //this.closeAudio();

          //this.addChatMessage("我: " + str, "sent");
          // this.getOpenAiReplay2(str);
          //this.captureAudio();

        }
      });
    },
    start_pay(audio_file){

      let that = this;
      audio_file = that.host+'/'+audio_file

      let ua = navigator.userAgent.toLowerCase();
      let isWeixin = ua.indexOf("micromessenger") != -1;
      if (isWeixin) {
        if (that.soundBgm != null) {
          that.soundBgm.pause();
          that.is_pay = false;
        }
        that.soundBgm = new Howl({
          src: [audio_file],
          loop: false,
          preload: true,
        });
        that.soundBgm.on("load", () => {
          that.soundBgm.play();
        });

        that.soundBgm.on("end", function () {
          console.log('播放结束')
          that.is_pay = false;
          //that.captureAudio();
          //that.startRecording()

        });
      } else {
        if (that.soundBgm != null) {
          that.soundBgm.pause();
          that.is_pay = false;
        }
        that.soundBgm = new Audio(audio_file);
        that.soundBgm.play();

        that.soundBgm.loop = false;
        that.soundBgm.addEventListener(
            "ended",
            function () {
              console.log('播放结束')
              that.is_pay = false;
              //that.captureAudio();
              //that.startRecording()

            },
            false
        );
      }
    },
    getOpenAiReplay2(prompt) {
      const url = this.host+"/api/createDebateDetail";
      let that = this;
      //let chatWindow = document.getElementsByClassName('contentSdk')[0]
      //let chatWindow = document.querySelector(".contentSdk");
      //chatWindow.innerHTML += ' <li><img src="../assets/left.png" class="imgleft"><div class="txtbox spanleft">'+prompt+'</div></li>';
      that.detail.push({debate: prompt, content: ''})
      that.textarea = ''
      setTimeout(() => {
        that.chatWindow.scrollTop = that.chatWindow.scrollHeight;
      }, 200);

      console.log(url);
      //this.addChatMessage("", 'received')

      fetch(url, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          "token": localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          debate: prompt,
          debate_id: that.debate_id,
        }),
      })
          .then(
              (response) => response.json(),
              (error) => {
                that.isLoading = false;
                console.error(error);
              }
          )
          .then((data) => {
            console.log(data);
            that.isLoading = false;
            if(data.code === 200) {
              // that.addChatMessage("AI: " + data.data.reply, "received");
              // that.start_pay(data.data.audio)
              //chatWindow.innerHTML += ' <li><img src="../assets/right.png" class="imgright"><div class="txtbox spanright">'+data.data.content+'</div></li>';
              //chatWindow.scrollTop = chatWindow.scrollHeight;
              that.textarea = ''
              that.detail.push({debate: '', content: data.data.content, audioFile: data.data.audioFile,is_pay: false})
              setTimeout(() => {
                that.chatWindow.scrollTop = that.chatWindow.scrollHeight;
              }, 200);
              //that.start_pay(data.data.audioFile)



            }else {
              console.log(data.data)
            }
            //console.log(data.choices[0].text);
            //this.addChatMessage("AI: " + data.choices[0].text, "received");

            //console.log("重新启动到纯音频");
            //this.captureAudio();
          });
    },
    addChatMessage(message, direction) {
      const chatWindow = document.querySelector("#chat-window");

      const messageElement = document.createElement("div");

      if(message === "") {
        messageElement.classList.add("dots-wrapper", "message", direction)
        //messageElement.innerHTML = '<div class="dot"></div><div class="dot"></div><div class="dot"></div>'
      }else{

        //const lastChild = chatWindow.lastElementChild;
        // chatWindow.removeChild(lastChild);

        messageElement.classList.add("message", direction);
        messageElement.innerHTML = '<p>' + message + "</p>";
      }

      chatWindow.appendChild(messageElement);
      chatWindow.scrollTop = chatWindow.scrollHeight;
    },
    removeLastEle(){
      const chatWindow = document.querySelector("#chat-window");
      const lastChild = chatWindow.lastElementChild;

      console.log('lastChild', lastChild.className)

      if(lastChild.className.indexOf('dots-wrapper') >= 0) {
        chatWindow.removeChild(lastChild);
      }

    }
  },
};
</script>

<style>
/* 加载动画样式 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading_box{
  width: 100%;
  height: 24px;
  margin: 5px auto;
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid rgb(11, 200, 217); /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  animation: spin 2s linear infinite;
}




.input_box{
  height: 60px;
  flex: 1;
  display: flex;
  margin-right: 10px;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}
.input_box .textarea{
  flex: 1;
  height: 60px;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  box-sizing: border-box;
  padding: 2px;
  overflow-y: auto;
  /*background: palegreen;*/


}
.input_box svg{
  width: 48px;
  height: 48px;
  /* margin-top: 5px;*/
  cursor: pointer;
}
.pay_box{
  width: 100%;
  margin: 8px auto 0px auto;
}
.pay_box_border{
  width: 50px;
  height: 26px;
  border: 1px solid rgb(11, 200, 217);
  border-radius: 15px;
  //display: inline-block;
  box-sizing: border-box;
  padding: 2px 5px;
  display: flex;
  justify-content: space-around;
}
.pay_box_border svg{
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.voices{cursor:pointer;flex-wrap:nowrap;width: 20px;display: flex}
.voices .loading{display:flex;align-items:center;}
.voices .loading hr {
  background-color: rgb(11, 200, 217);
  width: 1px;
  height: 3px;
  margin: 0 1px;
  border: none;
  /*  animation: note 0.2s ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;*/
}

.voices .loading hr.hr1 {
  animation-delay: -1s;
  transform: scaleY(2);
}

.voices .loading hr.hr2 {
  animation-delay: -0.9s;
  transform: scaleY(4);
}

.voices .loading hr.hr3 {
  animation-delay: -0.8s;
  transform: scaleY(2);
}

.voices .loading hr.hr4 {
  animation-delay: -0.7s;
  transform: scaleY(4);
}

.voices .loading hr.hr5 {
  animation-delay: -0.6s;
  transform: scaleY(2);
}



.voices.play .loading hr{
  animation-name:note;
  animation-duration:0.2s;
  animation-timing-function:ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.tooltip .tooltiptext {
  //visibility: hidden;
  width: 60px;
  height: 40px;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  //padding: 0px 20px;
  box-sizing: border-box;
  position: absolute;
  z-index: 999;
  bottom: 64px;
  //left: 50%;
  background: rgba(0,0,0,0.4);
  margin-left: -12px; /* 居中 */
  transition: opacity 0.3s;
}
.tooltip .tooltiptext1 {
  //visibility: hidden;
  width: 60px;
  height: 40px;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  //padding: 0px 20px;
  box-sizing: border-box;
  position: absolute;
  z-index: 999;
  bottom: 35px;
  //left: 50%;
  background: rgba(0,0,0,0.4);
  margin-left: -10px; /* 居中 */
  transition: opacity 0.3s;
}
.tooltip .tooltiptext2 {
  //visibility: hidden;
  width: 60px;
  height: 40px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  //padding: 0px 20px;
  box-sizing: border-box;
  position: absolute;
  z-index: 999;
  bottom: 62px;
  //left: 50%;
  background: rgba(0,0,0,0.4);
  margin-left: -7px; /* 居中 */
  transition: opacity 0.3s;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.voice{cursor:pointer;flex-wrap:nowrap;margin-right: 20px;}
.voice .loading{display:flex;align-items:center;}
.voice .loading hr {
  background-color: rgb(11, 200, 217);
  width: 2px;
  height: 5px;
  margin: 0 2px;
  border: none;
  animation: note 0.2s ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.voice .loading hr.hr1 {
  animation-delay: -1s;
}

.voice .loading hr.hr2 {
  animation-delay: -0.9s;
}

.voice .loading hr.hr3 {
  animation-delay: -0.8s;
}

.voice .loading hr.hr4 {
  animation-delay: -0.7s;
}

.voice .loading hr.hr5 {
  animation-delay: -0.6s;
}

.voice .loading hr.hr6 {
  animation-delay: -0.5s;
}
.voice .loading hr.hr7 {
  animation-delay: -0.4s;
}
.voice .loading hr.hr8 {
  animation-delay: -0.3s;
}
.voice .loading hr.hr9 {
  animation-delay: -0.2s;
}
.voice .loading hr.hr10 {
  animation-delay: -0.1s;
}
@keyframes note {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(4);
  }
}

.overlays {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 确保覆盖其他内容 */
}

.overlays.loading {
  display: flex;
}

.spinner {
  /*border: 4px solid rgba(255, 255, 255, 0.3);
border-top: 4px solid #fff;*/
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid rgb(11, 200, 217); /* Blue */

  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.input-container {
  display: flex;
  /* align-items: center;*/
  margin-bottom: 15px;
}

.input-container label {
  //flex: 0 0 auto;
  margin-right: 10px;
  width: 60px; /* 调整 label 宽度 */
  text-align: right;
  vertical-align: middle;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #666;
}

.input-container input[type="text"],
.input-container textarea {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 14px;
  height: 32px;
  line-height: 32px;

}

.input-container textarea {
  height: 96px; /* 调整 textarea 高度 */
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* Dialog styles */
.dialog {
  width: 520px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}


html,body {
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.app{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: url(../assets/loginbg-a3ffa5a4.png);
}
.left {
  width: 260px;
  height: 100%;
  margin-right: 10px;
  padding: 15px 0px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgb(234, 248, 254) 0%, rgb(255, 255, 255) 100%);
  box-shadow: 4px 0 4px #2b2d3703;
}
.left_header{
  width: 100%;
  height: 50px;
  padding: 3px 0px;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.left_list{
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: auto;
  box-sizing: border-box;

}
.left_list li{
  height: 46px;
  line-height: 46px;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  color: #666666;
  list-style-type: none;
  white-space: nowrap; /* 避免文本换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */

}
.left_list li.active{
  color: rgb(11, 200, 217);
  background: rgba(11, 200, 217, .05);
}
.addbtn {
  display: block;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  margin: 0 20px 10px;
  flex: none;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  background: rgb(11, 200, 217);
  cursor: pointer;
}
.right{
  flex: 1;
  height: 100%;
  box-sizing: border-box;
}
.result {
  height: 50px;
  margin: 10px;
  padding: 10px;
  background-color: #eee;
  border-radius: 10px;
  overflow: scroll;
}
.button_box{
  text-align: center;
}
.overlay button {
  margin-top: 10px;
  padding: 6px 12px;
  background-color: rgb(11, 200, 217);
  border: 1px solid rgb(11, 200, 217);
  color: white;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition-duration: 0.3s;
}

/*button:hover {
  background-color: rgb(11, 200, 217);;
}*/
.overlay .closebut{
  background-color: #dedede;
  color: #666;
  border:1px solid #dedede;
}

.overlay button:disabled {
  background-color: #aaa;
  cursor: default;
}

button,
audio {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.stop-button {
  background-color: #f44336;
}



#chat-window {
  width: 100%;
  height: calc(100% - 60px);
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  overflow-y: auto;
}

.message {
  display: flex;
  margin: 10px 0;
}

.message.received {
  justify-content: flex-end;
}

.message p {
  background-color: #eee;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #ddd;
  max-width: 80%;
}

.message.received p {
  text-align: left;
  background-color: #fff;
}

.message.sent p {
  background-color: #dcf8c6;
}

#operator {
  display: flex;
  width: 98%;
  margin: 0 auto 10px auto;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  border:1px #dedede solid;
  border-radius: 5px;
  background: #ffffff;

}

.dots-wrapper {
  display: flex;
  align-items: center;
}

.dot {
  display: inline-block;
  margin-right: 0.3em;
  width: 0.5em;
  height: 0.5em;
  background-color: #333;
  border-radius: 50%;
  animation: scale 1s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
.contentSdk {
  width: 100%;
  height: calc(100% - 80px);
  margin: 0 auto;
  display: flex;
  align-content: space-between;
  flex-direction: column;


  //flex: 1;
  //font-size: 16px;
  //width: 100%;
  //overflow-y: auto;
  //margin: 0 auto;
  //padding: 10px 8px;
  //box-sizing: border-box;
  //background:rgba(255,255,255,0.8);
}
.contentSdk .li_box{
  flex: 1;
  /*height: calc(100% - 40px);*/
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow-y: auto;
}
.contentSdk li {
  margin-top: 10px;
  padding-left: 10px;
  display: block;
  clear: both;
  overflow: hidden;
}
.contentSdk li img {
  width: 40px;
  height: 40px;
  float: left;
  display: block;
}

.contentSdk li .imgleft {
  float: left;
  border-radius: 50%;
}
.contentSdk li .imgright {
  float: right;
  border-radius: 50%;
}
.contentSdk li .spanleft {
  float: left;
  background: #fff;
}
.contentSdk li .spanright {
  float: right;
  /*color: #fff;*/
  background: #fff;
  /* background:  rgba(123, 152, 242, 1);*/
}

.contentSdk::-webkit-scrollbar {
  width: 4px;
}

.contentSdk::-webkit-scrollbar-track {
  background-color: unset;
}


.contentSdk::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(122, 173, 255, 0.5);
  border-radius: 2px;
}


.contentSdk::-webkit-scrollbar-thumb:hover {
  background: rgba(122, 173, 255, 0.5);
}
.li_box::-webkit-scrollbar {
  width: 4px;
}

.li_box::-webkit-scrollbar-track {
  background-color: unset;
}


.li_box::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(122, 173, 255, 0.5);
  border-radius: 2px;
}


.li_box::-webkit-scrollbar-thumb:hover {
  background: rgba(122, 173, 255, 0.5);
}
.textarea::-webkit-scrollbar {
  width: 2px;
}

.textarea::-webkit-scrollbar-track {
  background-color: unset;
}


.textarea::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(122, 173, 255, 0.5);
  border-radius: 2px;
}


.textarea::-webkit-scrollbar-thumb:hover {
  background: rgba(122, 173, 255, 0.5);
}
.contentSdk li div{
  //background: rgba(123, 152, 242, 1);
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  float: left;
  /*  margin: 0 10px;
    max-width: 310px;*/
  /*  border: 1px solid #ccc;
    box-shadow: 0 0 3px #ccc;*/
  font-size: 14px;
  max-width: 84%;
  line-height: 28px;
  color: #333333;
}
.contentSdk li img.imgleft {
  float: left;
  border-radius: 50%;
  margin-right: 10px;
}
.contentSdk li img.imgright {
  float: right;
  border-radius: 50%;
  margin-left: 10px;
}
.contentSdk li div.spanleft {
  float: left;

  background: #fff;
}
.contentSdk li div.spanright {
  float: right;
  background: #fff;
  /* background: rgba(123, 152, 242, 1);*/
}
.contentSdk li p,.contentSdk li span{
  font-size: 14px;
}
.contentSdk .txtbox{
  //max-width: 85%;
}
.summary {
  width: 40px;
  height: 40px;
  margin-right: 20px;

}
.summary span {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgb(11, 200, 217);
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  //position: absolute;
  //right: 5px;
  //color: #ffffff;
  border-radius: 50%;
  /*margin-top: 4px;*/
}

.summary span svg{
  margin-top: 4px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  font-size: 18px;
}
.footerSdk{
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.footerSdk span {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: rgb(11, 200, 217);
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  position: absolute;
  /*right: 5px;*/
  color: #ffffff;
  border-radius: 50%;
  /*margin-top: 4px;*/
}
.footerSdk span:hover {
  color: #fff;
}
.footerSdk span svg{
  margin-top: 4px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  font-size: 18px;
}
</style>
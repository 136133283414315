<template>
  <div class="login">
    <div class="login-container">
      <h2>登录</h2>
      <form @submit.prevent="login">
        <div class="form-group">
          <input type="text" id="mobile" v-model="mobile" required placeholder="用户名">
        </div>
        <div class="form-group">
          <input type="password" id="password" v-model="password" required placeholder="密码">
        </div>
        <button type="submit">登录</button>
      </form>
    </div>
    <!-- 引用消息提示组件 -->
    <Message :key="messageKey" :message="message" :type="messageType" />
  </div>
</template>

<script>
import Message from '../utils/Message.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name:'Login',
  components: {
    Message
  },
  data() {
    return {
      host: "https://arguer.newmin.cn/user/login",
      //host: "http://127.0.0.1:8032/user/login",
        mobile: '',
        password: '',
         message: '',
        messageKey: 0,
        messageType: 'error' // 可以是 'error', 'success', 'info', 'warning'
    }
  },
  methods: {
    login() {
      let that = this;
      fetch(that.host, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          mobile: that.mobile,
          password: that.password,
        }),
      }) .then(
          (response) => response.json(),
          (error) => {
            console.error(error);
          }
      ).then((data) => {
        console.log(data);
        if(data.code === 200) {
          that.message = '登录成功';
          that.messageType = 'success';
          that.messageKey++; // 增加key以强制更新组件
          //保存token
          localStorage.setItem('token', data.data.token);
          //跳转到首页
          that.$router.push('/');
        }else {
          that.message = data.data;
          that.messageType = 'error';
          that.messageKey++; // 增加key以强制更新组件
          console.log(data.data)
        }

      });
    }
  }
}
</script>

<style>
.login {
  font-family: 'Arial', sans-serif;
  background-color: #f0f2f5;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

h2 {
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  color: #333333;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.login button:hover {
  background-color: #0056b3;
}
</style>
